import useFireSearch from './useFireSearch'
import { accountsSetPage, setProfiles } from '../store/slices/userSlice'
import { useAppDispatch, useAppSelector } from './redux'
import { fetchSearchedUsers } from '../store/actionsCreators/userActions'
import { chatSetInvitePage, chatSetPage, setSearchInvites, setSearchMessages } from '../store/slices/chatSlice'
import { searchInvites, searchMessages } from '../store/actionsCreators/chatActions'
import { type RootState } from '../store/store'

import {
    applyUnitSearch,
    applyUnitSearchSearch,
    fetchCatalogUnits,
    fetchSearchCatalogUnits
} from '../store/actionsCreators/unitsActions'
import {
    setSearchAreasFound, setSearchAreasPageCount, setSearchNoAreasFound, setUnitsSearchCatalog,
    unitsSetAreasFound,
    unitsSetAreasPageCount,
    unitsSetCatalog, unitsSetNoAreasFound,
    unitsSetPage,
    unitsSetPageCount, unitsSetSearchPage, unitsSetSearchPageCount
} from '../store/slices/unitsSlice'
import { useEffect } from 'react'

const UseSearchProvider = (): void => {
    const dispatch = useAppDispatch()

    // const accountsPage = useAppSelector((state) => state.user.page)
    // const usersSearchString = useAppSelector((state) => state.user.searchString)

    const messagesPage = useAppSelector((state: RootState) => state.chat.page)
    const messagesSearchString = useAppSelector((state: RootState) => state.chat.searchString)

    const invitePage = useAppSelector((state: RootState) => state.chat.invitePage)
    const invitesSearchString = useAppSelector((state: RootState) => state.chat.invitesSearchString)

    // const unitsPage = useAppSelector((state: RootState) => state.units.page)
    // const unitsSearchString = useAppSelector((state: RootState) => state.units.searchString)
    // const unitFilters = useAppSelector((state: RootState) => state.units.filters)
    // const unitSearchFilters = useAppSelector((state: RootState) => state.units.searchFilters)
    // const unitCategory = useAppSelector((state: RootState) => state.units.unitsCategory)

    // const unitsSearchSearchString = useAppSelector((state: RootState) => state.units.searchSearchString)
    // const unitSearchSearchFilters = useAppSelector((state: RootState) => state.units.searchSearchFilters)

    /**
     * Accounts search
     */

    /* useFireSearch({
        callback: () => {
            // console.log('ACCOUNTS SEARCH TRIGGERED')
            dispatch(accountsSetPage(1))
            setTimeout(() => {
                //       console.log('ACCOUNTS BEFORE SEARCH USERS')
                dispatch(fetchSearchedUsers(accountsPage, () => {
                    dispatch(setProfiles([]))
                }, true))
                //   console.log('ACCOUNTS AFTER SEARCH USERS')
            })
        },
        delay: 100,
        controlValue: usersSearchString
    }) */

    /**
     * Messages search
     */

    useFireSearch({
        callback: () => {
            dispatch(chatSetPage(1))
            dispatch(setSearchMessages([]))
            dispatch(searchMessages(messagesPage, false, () => {
                dispatch(setSearchMessages([]))
            }))
        },
        delay: 100,
        controlValue: messagesSearchString
    })

    /**
     * Invites search
     */

    // useFireSearch({
    //     callback: () => {
    //         dispatch(chatSetInvitePage(1))
    //         dispatch(setSearchInvites([]))
    //         dispatch(searchInvites(invitePage))
    //     },
    //     delay: 100,
    //     controlValue: invitesSearchString
    // })

    /**
     * Search in units catalog

    useFireSearch({
        callback: () => {
            dispatch(unitsSetPage(1))
            dispatch(unitsSetPageCount(-1))
            dispatch(unitsSetAreasFound(false))
            dispatch(unitsSetNoAreasFound(false))
            dispatch(unitsSetAreasPageCount(0))
            dispatch(unitsSetCatalog([]))
            dispatch(applyUnitSearch())
        },
        delay: 100,
        controlValue: unitsSearchString
    })

    useEffect(() => {
        dispatch(unitsSetPage(1))
        dispatch(unitsSetPageCount(-1))
        dispatch(unitsSetAreasFound(false))
        dispatch(unitsSetNoAreasFound(false))
        dispatch(unitsSetAreasPageCount(0))
        dispatch(unitsSetCatalog([]))
        dispatch(fetchCatalogUnits(1, unitCategory))
    }, [unitCategory, unitFilters, unitSearchFilters])
     */

    /**
     * Areas search
     */

    /* useFireSearch({
        callback: () => {
            dispatch(unitsSetSearchPage(1))
            dispatch(unitsSetSearchPageCount(-1))
            dispatch(setSearchAreasFound(false))
            dispatch(setSearchNoAreasFound(false))
            dispatch(setSearchAreasPageCount(0))
            dispatch(setUnitsSearchCatalog([]))
            dispatch(applyUnitSearchSearch())
        },
        delay: 100,
        controlValue: unitsSearchSearchString
    }) */

    /* useEffect(() => {
        dispatch(unitsSetSearchPage(1))
        dispatch(unitsSetSearchPageCount(-1))
        dispatch(setSearchAreasFound(false))
        dispatch(setSearchNoAreasFound(false))
        dispatch(setSearchAreasPageCount(0))
        dispatch(setUnitsSearchCatalog([]))
        dispatch(fetchSearchCatalogUnits(1))
    }, [unitSearchSearchFilters]) */
}

export default UseSearchProvider
