import { type IChatMessage } from '../../../types'
import _ from 'lodash'
import { store } from '../../../store/store'
import customLog from '../../log'

export const getSingleMessageAdapter = (message: any): IChatMessage => {
    const user = _.find(message?.attributes?.ChatRoom?.data?.attributes?.Users?.data, (user) => user.id !== store.getState().user?.currentUser?.id)
    const isBSOBroadcast = message?.attributes?.ChatRoom?.data?.id === Number(process.env.REACT_APP_BSO_BROADCAST_ID)
    const isBSOSupport = user?.id === Number(process.env.REACT_APP_BSO_USER_ID) && !isBSOBroadcast
    const isMyMessage = message?.attributes?.Sender?.data?.id === store.getState().user.currentUser.id
    return {
        id: message?.id,
        Text: message?.attributes?.Text ?? '',
        updatedAt: message?.attributes?.updatedAt ?? '',
        createdAt: message?.attributes?.createdAt ?? '',
        Attachments: {
            data: message?.attributes?.Attachments?.[0]?.data ?? '',
            name: message?.attributes?.Attachments?.[0]?.name ?? '',
            type: message?.attributes?.Attachments?.[0]?.type ?? '',
            size: message?.attributes?.Attachments?.[0]?.size ?? 0,
            duration: message?.attributes?.Attachments?.[0]?.duration ?? undefined
        },
        Sender: message?.attributes?.Sender?.data?.id ?? -1,
        User: {
            FirstName: !isMyMessage && isBSOSupport ? 'BSO' : message?.attributes?.Sender?.data?.attributes?.ContactInfo?.FirstName,
            FamilyName: !isMyMessage && isBSOSupport ? 'Support' : message?.attributes?.Sender?.data?.attributes?.ContactInfo?.FamilyName,
            Avatar: message?.attributes?.Sender?.data?.attributes?.ContactInfo?.Avatar
        },
        ChatRoom: message?.attributes?.ChatRoom?.data?.id ?? -1,
        ChatWith: isBSOBroadcast
            ? 'BSO Broadcast'
            : isBSOSupport
                ? 'BSO Support'
                : `${String(user?.attributes?.ContactInfo?.FirstName ?? '')} ${String(user?.attributes?.ContactInfo?.FamilyName ?? '')}`,
        Unit: {
            attributes: {
                id: message?.attributes?.Unit?.data?.id,
                ...(message?.attributes?.Unit?.data?.attributes ?? {}),
                Property: message?.attributes?.Unit?.data?.attributes?.Property?.data?.attributes
            }
        },
        Status: message?.attributes?.ChatRoom?.data?.attributes?.Status,
        HideDate: message?.attributes?.HideDate
    }
}

const getMessageAdapter = (data: any): IChatMessage[] => {
    const messages = data?.response?.data ?? []
    return _.map(messages, getSingleMessageAdapter)
}

export default getMessageAdapter
